export const DEFAULT_COURIER_PRICE = 390;
export const DEFAULT_PICKUP_PRICE = 230;

export const DELIVERY_DEFAULT_CITY = {
  id: 2,
  text: 'Москва',
  value: 2,
  courier: true,
  pickup: true,
};

export const BOXBERRY = 'BOXBERRY';
export const CDEK = 'CDEK';
export const FIVE_POST = 'FIVE_POST';
