import axios from 'axios';

const API_VERSION_HEADER = process.env.API_VERSION_HEADER || 'v9';
const API_URL = process.env.GATSBY_API_URL || 'https://test-api1.periodica.press/api';
const defaultHeaders = { 'Accept-Version': API_VERSION_HEADER, 'Content-Type': 'application/json' };

interface DeliveryServicesArgs {
  cityId: number;
  isPickupDelivery?: boolean;
}

export const getDeliveryServices = async ({
  cityId,
  isPickupDelivery = false,
}: DeliveryServicesArgs) => {
  try {
    const res = await axios.get(
      `${API_URL}/delivery/info?city_id=${cityId}&pickup_point_delivery=${isPickupDelivery}`,
      { headers: defaultHeaders }
    );

    if (res.status >= 400) {
      throw new Error('Bad response from server');
    }

    return res.data.result;
  } catch (error: any) {
    throw new Error('getDeliveryServices request error:', error);
  }
};
